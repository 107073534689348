import React, { useState } from "react";
import {
  Fab,
  Paper,
  withStyles,
  Zoom,
  makeStyles,
  Hidden,
  Box,
} from "@material-ui/core";
import Carousel from "nuka-carousel";
import { PaperProps } from "@material-ui/core/Paper";
import { useStaticQuery, graphql } from "gatsby";
import PhotoDialog from "./PhotoDialog";

const useUrls = (): { thumbnail: string; large: string }[] => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { glob: "gallery/*" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            publicURL
            relativeDirectory
          }
        }
      }
    }
  `);
  const thumbnailsDir = /^gallery.thumbnails$/;
  const largeDir = /^gallery.dialog$/;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const ts: string[] = data.allFile.edges
    .filter((e: any) => e.node.relativeDirectory.match(thumbnailsDir))
    .map((e: any) => e.node.publicURL as string);
  const ls: string[] = data.allFile.edges
    .filter((e: any) => e.node.relativeDirectory.match(largeDir))
    .map((e: any) => e.node.publicURL as string);
  /* eslint-enable */
  return ts.map((t, i) => ({
    thumbnail: t,
    large: ls[i],
  }));
};

// ----------------------------------------------------------------
//     公開するコンポーネント
// ----------------------------------------------------------------

/**
 * PC 向けの写真一覧です。
 *
 * xs サイズでは中身を表示しません。
 */
export const PCGallery: React.FC<{ mt?: number; slidesToShow: number }> = ({
  mt,
  slidesToShow,
}) => {
  const width = 160;
  const height = width * 1.5;
  const shadowMargin = 2;
  const marginX = 16;
  const framePadding = 16;
  const slideWidth = width + marginX;

  const frameWidth =
    slideWidth * slidesToShow - marginX + framePadding * 2 + shadowMargin * 2;
  const frameHeight = height + shadowMargin * 2;
  const urls = useUrls();
  const [displayedImage, setDisplayedImage] = useState<string | undefined>(
    undefined
  );

  return (
    <React.Fragment>
      <PhotoDialog
        src={displayedImage}
        onClose={() => setDisplayedImage(undefined)}
      />
      <PCBox mt={mt} heightPx={frameHeight}>
        <Carousel
          framePadding={`${framePadding}px`}
          slidesToShow={slidesToShow}
          slideWidth={`${slideWidth}px`}
          width={`${frameWidth}px`}
          style={{ marginLeft: "auto", marginRight: "auto" }}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <GalleryControl
              onClick={previousSlide}
              end={false}
              visible={currentSlide > 0}
            />
          )}
          renderCenterRightControls={({ nextSlide, currentSlide }) => (
            <GalleryControl
              onClick={nextSlide}
              end={true}
              visible={currentSlide + slidesToShow < urls.length}
            />
          )}
          renderBottomCenterControls={null}
        >
          {urls.map((u, i) => (
            <GalleryImage
              key={i}
              width={width}
              height={height}
              url={u.thumbnail}
              onClick={() => setDisplayedImage(u.large)}
              style={{ margin: shadowMargin }}
            />
          ))}
        </Carousel>
      </PCBox>
    </React.Fragment>
  );
};

const useMobileGalleryStyle = makeStyles((theme) => ({
  root: {
    overflowX: "scroll",
    whiteSpace: "nowrap",
    // 影がクリッピングされないために少しマージンをつける
    padding: "2px 0 6px",
    margin: "-2px 0 -6px",
    // これがあると横スクロールがスムーズになる(iPhone 用なんですけど)
    "-webkit-overflow-scrolling": "touch",
    // 各ブラウザ向けのスクロールバーを消す設定
    //
    // 参考: https://blogs.msdn.microsoft.com/kurlak/2013/11/03/hiding-vertical-scrollbars-with-pure-css-in-chrome-ie-6-firefox-opera-and-safari/
    // Firefox だけ PC で確認したらうまく動作しませんでした。
    // あまり需要がないと思われるので対応を諦めます。
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  image: {
    marginRight: theme.spacing(2),
    display: "inline-block",
    verticalAlign: "middle",
  },
  imageFirst: {
    marginLeft: theme.spacing(2),
  },
}));
/**
 * モバイル向けの写真一覧です。
 *
 * xs サイズ以外では中身を表示しません。
 */
export const MobileGallery: React.FC = () => {
  const urls = useUrls();
  const classes = useMobileGalleryStyle();
  const [displayedImage, setDisplayedImage] = useState<string | undefined>(
    undefined
  );
  return (
    <Hidden smUp>
      <PhotoDialog
        src={displayedImage}
        onClose={() => setDisplayedImage(undefined)}
      />
      <div className={classes.root}>
        {urls.map((u, i) => (
          <GalleryImage
            key={i}
            width={132}
            height={132 * 1.5}
            url={u.thumbnail}
            onClick={() => setDisplayedImage(u.large)}
            className={`${classes.image} ${i === 0 ? classes.imageFirst : ""}`}
          />
        ))}
      </div>
    </Hidden>
  );
};

// ----------------------------------------------------------------
//     サブコンポーネント
// ----------------------------------------------------------------

const GalleryControlButton = withStyles({
  root: {
    background: "white",
  },
})(Fab);

type GalleryControlProps = {
  end: boolean;
  onClick: () => void;
  visible: boolean;
};

/**
 * 写真をスライドするためのボタンです。
 */
const GalleryControl: React.FC<GalleryControlProps> = ({
  end,
  onClick,
  visible,
}) => (
  <Zoom in={visible}>
    <GalleryControlButton color="inherit" onClick={onClick} size="small">
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform={end ? "rotate(0)" : "rotate(180, 4, 6)"}>
          <path
            d="M1.70498 0L0.294983 1.41L4.87498 6L0.294983 10.59L1.70498 12L7.70498 6L1.70498 0Z"
            fill="black"
            fillOpacity="0.54"
          />
        </g>
      </svg>
    </GalleryControlButton>
  </Zoom>
);

const GalleryImage: React.FC<
  {
    width: number;
    height: number;
    url: string;
  } & PaperProps
> = ({ width, height, url, style, ...props }) => (
  <Paper
    style={{
      width,
      height,
      backgroundImage: `url(${url})`,
      backgroundSize: `auto ${width * 1.5}px`,
      backgroundPosition: "center top",
      ...(style || {}),
    }}
    {...props}
  />
);

/**
 * PC でのみ表示される Box です(PCGallery用)。
 *
 * Hidden を使う事によって起こる、読み込み時のリフローを起こさないようにしています。
 */
const PCBox: React.FC<{ mt?: number; heightPx: number }> = ({
  mt,
  heightPx: height,
  children,
}) => (
  // こちらの Hidden は CSS によって実装されているので、リフローが起こりません。
  <Hidden xsDown implementation="css">
    <Box mt={mt} style={{ height: `${height}px` }}>
      {/* こちらの Hidden は JS によって実装されているので、モバイルから開いた時は
       * 要素自体が存在せず、余計な処理も発生しません。 */}
      <Hidden xsDown>{children}</Hidden>
    </Box>
  </Hidden>
);
