import React from "react";
import { withStyles, makeStyles, Dialog } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";

const useImageDialogStyles = makeStyles((theme) => ({
  image: {
    // モバイルの場合はマージンをつけません。
    // それ以外の場合はある程度マージンがつきます
    maxWidth: "100vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: `calc(100vw - ${theme.spacing(8)}px)`,
      maxHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "800px",
      maxHeight: `calc(100vh - ${theme.spacing(12)}px)`,
    },
  },
}));
/**
 * 画像を拡大表示するダイアログです。
 */
const ImageDialogFrame = withStyles((theme) => ({
  paper: {
    // Dialog のコンテンツ(Paper)はデフォルトではマージンがついています。
    // 今回は画像自身にマージンがついているので、こちらにはつけません。
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      // モバイルの場合は角丸をつけません。
      borderRadius: 0,
    },
  },
}))(Dialog);
type PhotoDialogProps = Omit<DialogProps, "open" | "children"> & {
  src: string | undefined;
};
const PhotoDialog: React.FC<PhotoDialogProps> = ({ src, ...props }) => {
  const classes = useImageDialogStyles();
  return (
    <ImageDialogFrame maxWidth="md" {...props} open={!!src}>
      <img src={src} className={classes.image} />
    </ImageDialogFrame>
  );
};

export default PhotoDialog;
