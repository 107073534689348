import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Body, Subheadline } from "helpers/text";

const useStyle = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    marginTop: spacing(2),
    [breakpoints.up("sm")]: {
      marginTop: spacing(3),
    },
  },
  text: {
    "& a": {
      color: palette.primary.main,
    },
  },
}));

const NewsItem: React.FC<{ date: string; text: React.ReactNode }> = ({
  date,
  text,
}) => {
  const classNames = useStyle();
  return (
    <Grid container alignItems="baseline" className={classNames.root}>
      <Grid item xs={12} sm="auto" style={{ minWidth: 140 }}>
        <Subheadline color="secondary">{date}</Subheadline>
      </Grid>
      <Grid item xs={12} sm>
        <Body className={classNames.text}>{text}</Body>
      </Grid>
    </Grid>
  );
};

export default NewsItem;
