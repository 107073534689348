import React from "react";
import { withStyles } from "@material-ui/styles";
import { Paper, useMediaQuery, Theme } from "@material-ui/core";

const SectionSurface = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: 0,
    },
  },
}))(Paper);
export const Section: React.FC = (props) => {
  const large = !useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"));

  return <SectionSurface elevation={large ? 0 : 1} {...props} />;
};
