import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import { main, Layout } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { Body, Caption, Title, Subtitle } from "helpers/text";
import { Section } from "helpers/pages/index/common";
import {
  ScheduleBar,
  ScheduleEvent,
  ScheduleGallery,
} from "helpers/pages/index/schedule";
import TopSection from "helpers/pages/index/TopSection";
import NewsItem from "helpers/pages/index/NewsItem";
import { PCGallery, MobileGallery } from "helpers/pages/index/gallery";
import { makeText } from "helpers/i18n";
import { Sponsors } from "helpers/pages/index/Sponsors";
import { ExternalLink } from "helpers/Link";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    topText: "チケット販売開始",
    topLink: "http://droidkaigi.jp/TBA",
    about: (
      <React.Fragment>
        DroidKaigiはエンジニアが主役のAndroidカンファレンスです。
        <br />
        Android技術情報の共有とコミュニケーションを目的に、2022年10月5日(水)〜7日(金)の3日間開催します。
      </React.Fragment>
    ),
    detail: {
      dateTitle: "日時",
      dateValue: "2022年10月5日 (水)〜10月7日(金)",
      placeTitle: "場所",
      placeValue: (
        <React.Fragment>
          Online &amp; Offline <br />
          オフラインの場所: 東京ドームシティ プリズムホール <br />
          〒112-0004 東京都文京区後楽1-3-61
        </React.Fragment>
      ),
      access: (
        <React.Fragment>
          水道橋駅: JR中央・総武線/地下鉄三田線 <br />
          後楽園駅: 地下鉄丸の内線・南北線 <br />
          春日駅: 地下鉄大江戸線
        </React.Fragment>
      ),
      feeTitle: "参加費",
      feeValue: <React.Fragment>TBA</React.Fragment>,
    },
    mapUrl:
      "https://www.google.com/maps/place/1-ch%C5%8Dme-3-61+K%C5%8Draku,+Bunkyo+City,+Tokyo+112-0004/@35.7053366,139.7519632,17.05z/data=!4m5!3m4!1s0x60188c477c2b1661:0x293e44db0d9ffd1c!8m2!3d35.7053406!4d139.7521112",
    news: [
      {
        date: "2022.08.24",
        text: (
          <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/99216">
            チケット販売を開始しました
          </ExternalLink>
        ),
      },
    ],
    schedule: {
      ticketSales: { title: "チケット販売開始", date: "2022.08.24" },
      droidKaigi: { title: "DroidKaigi 開催", date: "2022.10.05" },
    },
  },
  en: {
    topText: "Ticket sales has started",
    topLink: "http://droidkaigi.jp/TBA",
    about: (
      <React.Fragment>
        DroidKaigi is a conference tailored for Android developers.
        <br />
        It&apos;s scheduled to take place on the 5 to 7 of October 2022.
      </React.Fragment>
    ),
    detail: {
      dateTitle: "Dates",
      dateValue: "5 - 7 October, 2022",
      placeTitle: "Place",
      placeValue: (
        <React.Fragment>
          Place : Online &amp; Offline <br />
          Offline venue: Tokyo Dome City PRISM HALL <br />
          1-3-61, Koraku, Bunkyo-ku, Tokyo 112-0004
        </React.Fragment>
      ),
      access: (
        <React.Fragment>
          Suidobashi Station: JR Chuo · Sobu Line/Subway Mita Line <br />
          Korakuen Station: Subway Marunouchi Line/Namboku Line <br />
          Kasuga Station: Subway Oedo Line
        </React.Fragment>
      ),
      feeTitle: "Participation Fee",
      feeValue: <React.Fragment>TBA</React.Fragment>,
    },
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3239.848865569918!2d139.7519632!3d35.7053366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c477c2b1661%3A0x293e44db0d9ffd1c!2z44CSMTEyLTAwMDQg5p2x5Lqs6YO95paH5Lqs5Yy65b6M5qW977yR5LiB55uu77yT4oiS77yW77yR!5e0!3m2!1sja!2sjp!4v1661076178601!5m2!1sja!2sjp",
    news: [
      {
        date: "2022.08.24",
        text: (
          <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/99216">
            Ticket sales has started
          </ExternalLink>
        ),
      },
    ],
    schedule: {
      ticketSales: { title: "Ticket Sales start", date: "24 August 2022" },
      droidKaigi: { title: "DroidKaigi", date: "5 - 7 October 2022" },
    },
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const IndexMain: React.FC = () => {
  const text = useText();
  return (
    <Layout>
      <TopSection text={text.topText} to={text.topLink} external />
      <Section>
        <MainContainer>
          <Title>What is DroidKaigi?</Title>
          <Body align="center" xsAlignLeft>
            {text.about}
          </Body>
        </MainContainer>
        <PCGallery mt={5} slidesToShow={5} />
      </Section>
      <MobileGallery />
      <Section>
        <MainContainer>
          {/* グリッドで余計な margin が発生するので marginBottomOffset で調整 */}
          <Title marginBottomOffset={-2}>Overview</Title>
          <Grid container>
            <Grid item sm={5}>
              <Subtitle>{text.detail.dateTitle}</Subtitle>
              <Body>{text.detail.dateValue}</Body>
              <Subtitle>{text.detail.feeTitle}</Subtitle>
              <Body>{text.detail.feeValue}</Body>
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={5}>
              <Subtitle>{text.detail.placeTitle}</Subtitle>
              <Body>{text.detail.placeValue}</Body>
              <Caption color="textSecondary">{text.detail.access}</Caption>
            </Grid>
          </Grid>
        </MainContainer>
      </Section>
      <iframe
        src={text.mapUrl}
        width="100%"
        height="500"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      ></iframe>
      <Section>
        <MainContainer>
          <Title>News</Title>
          {text.news.map(({ date, text }) => (
            <NewsItem key={date} date={date} text={text} />
          ))}
        </MainContainer>
      </Section>
      <Section>
        <MainContainer>
          <Title marginBottomOffset={2}>Schedule</Title>
          <Grid container>
            <Grid item sm={6} container>
              <Grid item xs="auto">
                <ScheduleBar />
              </Grid>
              <Grid item xs>
                <Box ml={4}>
                  <ScheduleEvent {...text.schedule.proposalStart} hasBottom />
                  <ScheduleEvent {...text.schedule.proposalEnd} hasBottom />
                  <ScheduleEvent {...text.schedule.sessions} hasBottom />
                  <ScheduleEvent {...text.schedule.ticketSales} hasBottom />
                  <ScheduleEvent {...text.schedule.droidKaigi} />
                </Box>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Grid sm={6} item>
                <ScheduleGallery />
              </Grid>
            </Hidden>
          </Grid>
        </MainContainer>
      </Section>
      <Sponsors />
    </Layout>
  );
};

export default main(IndexMain);
