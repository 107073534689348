import React, { useState } from "react";
import { useTheme, GridList, GridListTile } from "@material-ui/core";
import { Headline, Body } from "helpers/text";
import scheduleImage1 from "../../../images/gallery-schedule/image_1.jpg";
import scheduleImage2 from "../../../images/gallery-schedule/image_2.jpg";
import scheduleImage3 from "../../../images/gallery-schedule/image_3.jpg";
import PhotoDialog from "./PhotoDialog";

const scheduleBarHeight = 110;
export const ScheduleBar: React.FC = () => {
  const {
    palette: {
      primary: { main, light },
    },
  } = useTheme();
  return (
    <svg
      width="16"
      height="457"
      viewBox="0 0 16 457"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="346" rx="8" fill={main} />
      <circle cx="8" cy="9" r="6" fill={light} />
      <circle cx="8" cy="119" r="6" fill={light} />
      <circle cx="8" cy="229" r="6" fill={light} />
      <rect y="328" width="16" height="129" rx="8" fill={main} />
      <circle cx="8" cy="338" r="6" fill={light} />
      <circle cx="8" cy="448" r="6" fill={light} />
    </svg>
  );
};

type ScheduleEventProps = { title: string; date: string; hasBottom?: boolean };
export const ScheduleEvent: React.FC<ScheduleEventProps> = ({
  title,
  date,
  hasBottom,
}) => (
  <div style={hasBottom ? { height: scheduleBarHeight } : {}}>
    <Headline color="secondary" singleLine>
      {title}
    </Headline>
    <Body color="textSecondary">{date}</Body>
  </div>
);

export const ScheduleGallery: React.FC = () => {
  const [displayedImage, setDisplayedImage] = useState<string | undefined>(
    undefined
  );
  // スケジュール全体の高さに合い、かつ画像があまり切り取られない程度に
  // 高さを調整しています。
  const firstRowHeight = "233px";
  const secondRowHeight = "253px";

  return (
    <React.Fragment>
      <PhotoDialog
        src={displayedImage}
        onClose={() => setDisplayedImage(undefined)}
      />
      <GridList
        cols={5}
        spacing={16}
        cellHeight="auto"
        style={{ lineHeight: 1 }}
      >
        <GridListTile
          cols={5}
          style={{ height: firstRowHeight }}
          onClick={() => setDisplayedImage(scheduleImage1)}
        >
          <img src={scheduleImage1} />
        </GridListTile>
        <GridListTile
          cols={3}
          style={{ height: secondRowHeight }}
          onClick={() => setDisplayedImage(scheduleImage2)}
        >
          <img src={scheduleImage2} />
        </GridListTile>
        <GridListTile
          cols={2}
          style={{ height: secondRowHeight }}
          onClick={() => setDisplayedImage(scheduleImage3)}
        >
          <img src={scheduleImage3} />
        </GridListTile>
      </GridList>
    </React.Fragment>
  );
};
